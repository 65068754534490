import request from '@/router/axios';
import {businessUrl} from '@/config/env'

export const list = (pageNo, pageSize, machineId, shiftId, groupId, startTime, endTime, processId) => {
    return request({
        url: businessUrl + "shiftMachineData/selectShiftMachineData",
        method: "post",
        data: {
            pageNo,
            pageSize,
            machineId,
            shiftId,
            groupId,
            startTime,
            endTime,
            processId
        }
    })
}
export const detailsList = (row) => {
    return request({
        url: businessUrl + "shiftMachineData/selectUserYieldBySchedule",
        method: "post",
        data: {
            ...row

        }
    })
}
// 查询所有班制
export const selectShift = () => {
    return request({
        url: businessUrl + "shift/selectByCorpId",
        method: "get",
        params: {
            processId: ""
        }
    })
}
// 查询班组
export const selectGroupList = (id) => {
    return request({
        url: businessUrl + "department/selectGroupName",
        method: "post",
        params: {
            workShopId: id
        }
    })
}
// 查询设备
export const searcheQuipment = (id) => {
    return request({
        url: businessUrl + "machine/queryAllMachine",
        method: "post",
        data: {
            departmentId: id
        }
    })
}
// 查询车间
export const searchWorkshop = (num) => {
    return request({
        url: businessUrl + "department/selectWorkShopName",
        method: "get",
        params: {
            type: num
        }
    })
}
//查询班组
export const searchDepartment = (num) => {
    return request({
        url: businessUrl + "department/selectWorkShopName",
        method: "get",
        params: {
            type: num
        }
    })
}
export const getCommmit = (row, replaceUserList) => {
    return request({
        url: businessUrl + 'shiftMachineData/updateShiftMachineData',
        method: "post",
        data: {
            ...row,
            replaceUserList
        }
    })
}
export const getvarietiesById = (id) => {
    return request({
        url: businessUrl + "product/selectById",
        method: "get",
        params: {
            id: id
        }
    })
}
export const processselectAll = () => {
    return request({
        url: businessUrl + "process/selectAll",
        method: "get",
    })
}
export const getBatchById = (id) => {
    return request({
        url: businessUrl + "productBatch/selectBatchById",
        method: "get",
        params: {
            batchCodeId: id
        }
    })
}
export const selectUserList = (departmentId, postId, status) => {
    return request({
        url: businessUrl + "corpUsers/selectByDepartmentId",
        method: "post",
        data: {
            departmentId,
            postId,
            status
        }
    })
}
export const searchPost = () => {
    return request({
        url: businessUrl + "post/selectAllPost",
        method: "post",
    })
}
export const getSaveYield = (replaceUserList) => {
    return request({
        url: businessUrl + "shiftMachineData/updateShiftMachineData",
        method: "post",
        data: {
            replaceUserList
        }
    })
}
