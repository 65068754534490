<template>
  <div>
    <basic-container>
      <el-dialog
          title='人员产量详情'
          :visible.sync="dialogVisible"
          width="70%">
        <avue-crud :option="tableOptions" :data="tableDatas" @row-update="rowUpdate" ref="cruds">
          <template slot-scope="{row,index}" slot="menu">
            <div style="height:30px;" v-if="row.status == 0"/>
            <el-button type="primary" size="small" v-if="!row.$cellEdit" @click="rowCell(row,index)">修改人员产量
            </el-button>
            <el-button type="success" icon="el-icon-check" size="small" v-else @click="rowCell(row,index)">
              完成编辑
            </el-button>
          </template>
        </avue-crud>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
          <el-button type="primary" @click="saveYield">{{ $t("Save") }}</el-button>
        </span>
      </el-dialog>
      <!-- 最后的弹框 -->
      <el-dialog
          title="人员细分"
          :visible.sync="dialogVisibles"
          width="70%">
        <avue-crud :option="tableOptionss" :data="tableDatass" ref="crud">

          <template slot-scope="scope" slot="menu">
            <div style="height:30px;" v-if="scope.row.status == 0"/>
            <el-button type="text"
                       size="small"
                       plain
                       class="none-border"
                       @click.stop="update(scope.row,scope.index)">{{ $t("Edit") }}
            </el-button>
          </template>
          <template slot="menuRight">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" labelWidth="90px"
                     style="display:flex;">
              <el-date-picker clearable
                              v-model="cve.startTimess"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              size="small"
                              placeholder="开始日期" style="width:150px !important;margin-right: 5px;">
              </el-date-picker>
              <el-date-picker clearable=""
                              v-model="cve.endTimess"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              type="datetime"
                              size="small"
                              placeholder="结束日期" style="width:150px !important;margin-right: 5px;">
              </el-date-picker>
              <el-input v-model="cve.machineId" :disabled='true' :placeholder="$t('Please Enter Output')"
                        style="display:inlie-block;width:100px;marginRight: 5px;" size="small"></el-input>
              <el-select filterable clearable v-model="cve.deptmentId" @change="bumen" placeholder="部门"
                         size="small" style="width:100px !important;marginRight: 5px;">
                <el-option v-for="item in deptmentList" :key="item.id" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
              <el-select filterable clearable v-model="cve.gangweiId" @change="gangwei" placeholder="岗位"
                         size="small" style="width:100px !important;marginRight: 5px;">
                <el-option v-for="item in postList" :key="item.id" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
              <el-select filterable clearable v-model="cve.userId" placeholder="人员" size="small"
                         style="width:100px !important;marginRight: 5px;">
                <el-option v-for="item in userList" :key="item.id" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
              <el-input v-model="cve.outputs" :placeholder="$t('Please Enter Output')"
                        style="display:inlie-block;width:100px;marginRight: 5px;" size="small"></el-input>
              <el-input v-model="cve.consumptions" :placeholder="$t('Please Enter Energy Consumption')"
                        style="display:inlie-block;width:100px;marginRight: 5px;" size="small"></el-input>
              <el-input v-model="cve.standSpin" :placeholder="$t('Please Enter Energy Consumption')"
                        style="display:inlie-block;width:100px;marginRight: 5px;" size="small"></el-input>
              <el-button type="primary" @click="savess" size="mini" style="height:32px;padding-top: 8px;">
                {{ $t("save") }}
              </el-button>
            </el-form>
          </template>
        </avue-crud>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
          <el-button type="primary" @click="sub">{{ $t("Save") }}</el-button>
        </span>
      </el-dialog>
      <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page.sync="page"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 ref="crud"
                 @refresh-change="refreshChange"
                 @row-update="handleUpdate"
      >
        <template slot-scope="scope" slot="menu">
          <el-button type="text"
                     size="small"
                     plain
                     class="none-border"
                     @click.stop="handleView(scope.row,scope.index)">{{ $t("View Users Report") }}
          </el-button>
        </template>
        <template slot="menuRight">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" labelWidth="90px"
                   style="display:flex;">
            <el-date-picker clearable
                            v-model="startTime"
                            value-format="yyyy-MM-dd"
                            size="small"
                            type="date"
                            placeholder="开始日期" style="width:150px !important;margin-right: 5px;">
            </el-date-picker>
            <el-date-picker clearable
                            v-model="endTime"
                            value-format="yyyy-MM-dd"
                            size="small"
                            type="date"
                            placeholder="结束日期" style="width:150px !important;margin-right: 5px;">
            </el-date-picker>
            <el-select filterable clearable v-model="processselectAllID" placeholder="工序" size="small"
                       style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in processselectAllData" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <el-select filterable clearable v-model="shiftId" placeholder="班制" size="small"
                       style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in shiftList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <el-select filterable clearable v-model="workShopId" @change="chejian" placeholder="车间"
                       size="small" style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in workshopList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <el-select filterable clearable v-model="groupId" placeholder="班组" size="small"
                       style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in groupList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <el-select filterable clearable v-model="machineId" placeholder="设备" size="small"
                       style="width:100px !important;marginRight: 5px;">
              <el-option v-for="item in machineList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
            <el-button type="primary" @click="search" size="mini" style="height:32px;padding-top: 8px;">
              {{ $t("search") }}
            </el-button>
          </el-form>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {
  selectShift,
  selectGroupList,
  searcheQuipment,
  list,
  detailsList,
  searchWorkshop,
  getCommmit,
  getvarietiesById,
  getBatchById,
  selectUserList,
  searchDepartment,
  searchPost,
  getSaveYield,
  processselectAll
} from "@/api/usersStyle";
import standardListVue from '../wkln/standardList.vue';

export default {
  data() {
    return {
      processselectAllID: '',
      processselectAllData: [],
      newarr: [],
      saveYieldList: [],//保存产量的数组
      tableDa: [],
      gangweiId: '',
      bumenId: '',
      dialogVisibles: false,
      tableDatass: [],
      quipmentList: [],
      // 弹出层
      getRow: {},
      table: [],
      varieties: '',
      batch: '',
      tableDatas: [],
      tableOptionss: {
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        addBtn: false,
        menuWidth: 180,
        columnBtn: false,
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: "人员名称",
          prop: "corpUserName",
        }, {
          label: "岗位名称",
          prop: "postName"
        }, {
          label: "设备名称",
          prop: "machineName",
        }, {
          label: "开始时间",
          prop: "startTime"
        }, {
          label: "结束时间",
          prop: "endTime"
        }, {
          label: "产量(kg)",
          prop: "yield"
        }, {
          label: "能耗(kwh)",
          prop: "energy"
        }, {
          label: "看台锭数",
          prop: "standSpin"
        }]
      },
      tableOptions: {
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        addBtn: false,
        menuWidth: 180,
        columnBtn: false,
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: "人员名称",
          prop: "corpUserName",
        }, {
          label: "岗位名称",
          prop: "postName"
        }, {
          label: "设备名称",
          prop: "machineName",
        }, {
          label: "开始时间",
          prop: "startTime"
        }, {
          label: "结束时间",
          prop: "endTime",
        }, {
          label: "产量(kg)",
          prop: "userYield",
          cell: true
        }, {
          label: "能耗(kwh)",
          prop: "userEnergy"
        }, {
          label: "看台锭数",
          prop: "standSpin"
        }]
      },
      formInline: {},
      formInlines: {},
      equipments: '', //设备名称
      startTimes: '',
      endTimes: '',
      outputs: '',
      consumptions: '',
      shifts: '', //班制
      postIds: '', //批号
      // 其他------
      equipment: '', //设备名称
      output: '', //产量
      consumption: '', //能耗
      shiftList: [],//班制的列表
      machineList: [],//设备列表
      groupList: [],//班组列表
      userList: [],//人员列表
      startTime: null, //开始时间
      endTime: null, //结束时间
      pinzhongId: null, //品种Id
      tableLoading: false,
      workshopList: [], //车间列表
      deptmentList: [],//部门列表
      postList: [],//岗位列表
      workLists: [],
      workList: [],
      dialogVisible: false,
      page: {
        pageSize: 20,
        total: 0,
        pageCurrent: 1,
      },
      workShopId: '',
      shiftId: '', //班制id
      groupId: '', // 班组id
      machineId: '',
      postId: '', //批号
      cve: {
        pageSize: 20,
        total: '',
        pageCurrent: 1,
        machineId: '',
        startTimess: '', //开始时间
        endTimess: '', // 结束时间
        deptmentId: '', //部门id
        gangweiId: '', //岗位id
        userId: '',//人员id
        outputs: '', // 产量
        consumptions: '' //能耗
      },
      input: "",
      tableData: [],
      tableOption: {
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        addBtn: false,
        menuWidth: 180,
        columnBtn: false,
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: "班制名称",
          prop: "shiftName"
        }, {
          label: "班组名称",
          prop: "groupName"
        }, {
          label: "设备名称",
          prop: "machineName"
        }, {
          label: "开始时间",
          prop: "startTime"
        }, {
          label: "结束时间",
          prop: "endTime"
        }, {
          label: "产量(kg)",
          prop: "yield"
        }, {
          label: "能耗(kwh)",
          prop: "energy"
        }]
      }
    }
  },
  created() {
    processselectAll().then((response) => {
      console.log(response.data)
      this.processselectAllData = response.data;
    })
    // list().then(res => {
    //   console.log('哈哈哈',res);
    //     // this.tableData = res.data.data.data
    // })
    // 查询班制
    selectShift().then(res => {
      console.log('班制的list', res);
      this.shiftList = res.data.data
      this.shiftId = res.data.data[0].id
      this.search()

    }),
        // 查询车间
        searchWorkshop(1).then(res => {
          console.log('车间', res.data);
          this.workshopList = res.data.data;
        }),
        // 查询部门
        searchDepartment(0).then(res => {
          console.log('部门', res.data);
          this.deptmentList = res.data.data
        }),
        //查询岗位
        searchPost().then(res => {
          console.log('岗位', res.data);
          this.postList = res.data.data
        }),
        this.getTimeFn();
  },
  methods: {
    rowCell(row, index) {
      this.$refs.cruds.rowCell(row, index)
    },
    rowCancel(row, index) {
      this.$refs.cruds.rowCancel(row, index)
    },
    rowUpdate(form, index, done) {
      this.saveYieldList.push(form)
      this.newarr = Array.from(new Set(this.saveYieldList));
      done()
      // this.newarr = (...new Set(this.saveYieldList));
      // console.log(form, this.saveYieldList)
      // console.log(form, index, done)
    },
    saveYield() {
      console.log(1);
      getSaveYield(this.newarr).then(res => {
        if (res.data.code == '0000') {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success"
          });
          this.dialogVisible = false
        }
        console.log(res);
      })
    },
    //   dpinzhong(e) {
    //      groupList(e).then(res => {
    //             console.log('ssss',res);
    //             this.workList = res.data.data
    //           })
    //     getvarietiesById(e).then(res => {
    //       console.log(res,'mingzi');
    //       this.varieties = res.data.data.name
    //     })
    //     console.log('val',e);
    //   },
    //   dpihao(val){
    //     groupList(val).then(res => {
    //       this.groupIds = res.data.data.id
    //       console.log('id',res);
    //     })
    //     console.log('123',val);
    //   },
    //   handleClose(done) {
    //     this.$confirm('确认关闭？')
    //       .then(_ => {
    //         done();
    //       })
    //       .catch(_ => {});
    //   },
    //   pinzhong(val) {
    //         if(this.shiftId == '') {
    //           return
    //         }else {
    //           groupList(val).then(res => {
    //             console.log('批号',res);
    //             this.workLists = res.data.data
    //           })
    //         }
    //       // this.pinzhongId = val;
    //   },
    addConfirm() {

    },
    cjChange() {

    },
    chejian(val) {
      console.log('val', val);
      if (this.workShopId == '') {
        return
      } else {
        // 查询设备
        searcheQuipment(val).then(res => {
          console.log('设备', res.data);
          this.machineList = res.data.data.items
        })
        //查询班组
        selectGroupList(val).then(res => {
          console.log('班组', res);
          this.groupList = res.data.data
        })
      }
    },
    gangwei(val) {
      console.log('岗位', val);
      this.gangweiId = val
      selectUserList(this.bumenId, this.gangweiId, 0).then(res => {
        console.log('人员列表', res.data);
        this.userList = res.data.data
      })
    },
    bumen(val) {
      this.bumenId = val
      selectUserList(this.bumenId, this.gangweiId, 0).then(res => {
        console.log('人员列表', res.data);
        this.userList = res.data.data
      })
      // 查询人员list

    },
    search() {
      console.log(this.startTime, 'this.startTime')
      if (this.startTime == '' || this.endTime == '') {
        console.log('输入时间');
        alert('')
      } else {
        list(this.page.pageCurrent, this.page.pageSize, this.machineId, this.shiftId, this.groupId, this.startTime ? (this.startTime + ' 00:00:00') : '0000-00-00 00:00:00', this.endTime ? (this.endTime + ' 00:00:00') : '0000-00-00 00:00:00', this.processselectAllID).then(res => {
          //  console.log('=======',res,res.data.data.total);
          if (res.data.data.length == 0) {
            this.tableData = [];
            this.page.total = 0;
          } else {
            this.tableData = res.data.data.data;
            this.page.total = res.data.data.total;
          }
          console.log('=======', this.page.total);

          this.$forceUpdate()
        })
      }
    },
    handleUpdate() {

    },
    refreshChange() {

    },
    //点击查详情
    handleView(row) {
      console.log('row', row);
      // this.getRow = row
      this.tableDatas = [];
      this.dialogVisible = true
      this.equipments = row.machineName
      detailsList(row).then(res => {
        console.log('///////////', res);
        this.tableDatas = res.data.data
      })
    },
    //弹出框的删除
    del(row) {
      console.log(row);
      this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        console.log('row', row);
        console.log('this.tableDatas', this.tableDatas);
        this.tableDatas.splice(row, 1)
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success"
        });
      })
          .catch(() => {
          });
    },
    savess() {
      console.log('111');
      let objSave = {
        machineName: this.equipments,
        corpUserName: this.cve.userId,
        postName: this.cve.gangweiId,
        startTime: this.cve.startTimess,
        endTime: this.cve.endTimess,
        yield: this.cve.outputs,
        energy: this.cve.consumptions,
        standSpin: this.cve.standSpin
      }
      this.tableDatass.push(objSave)

    },
    // 弹出框的保存
    save() {
      console.log('弹出框的保存', this.equipments, this.varieties, this.batch, this.startTimes, this.endTimes, this.outputs, this.consumptions);
      let objSave = {
        machineName: this.equipments,
        productName: this.varieties,
        batchCode: this.batch,
        startTime: this.startTimes,
        endTime: this.endTimes,
        yield: this.outputs,
        energy: this.consumptions
      }
      this.tableDatas.push(objSave)
    },
    // 弹出框的提交
    sub() {
      console.log('1111');
      // let tableDa = []
      let subObj = {
        replaceUserId: this.cve.userId, // 切点班制
        replacePostId: this.cve.gangweiId,// 切点批号id
        ReplaceBeginTime: this.cve.startTimess,// 切点时间
        ReplaceEndTime: this.cve.endTimess,// 切点结算时间
        ReplaceEnergy: this.cve.outputs,// 开始-切点品种 的产量
        ReplaceYield: this.cve.consumptions,// 开始-切点品种 的能耗
        ReplaceStandSpin: this.cve.standSpin,// 开始-切点品种 的能耗
      }
      this.tableDa.push(subObj)
      console.log('row2', row);
      getCommmit(this.getRow, this.tableDa).then(res => {
        console.log('res', res);
        this.dialogVisible = false
        this.search()
        //  list(this.page.pageNo,this.page.pageSize,this.page.processId,this.page.postId,this.groupId,this.startTime+' 00:00:000',this.endTime + ' 00:00:000').then(res => {
        //     this.tableData = res.data.data.data
        //  })
      })
      console.log('弹出框的提交');
    },
    currentChange(pageCurrent) {
      this.page.pageCurrent = pageCurrent;
      this.search();
    },
    sizeChange(pageSize) {
      this.page.pageCurrent = 1;
      this.page.pageSize = pageSize;
      this.search();
    },
    getTimeFn() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      this.startTime = this.formatDate(start);
      this.endTime = this.formatDate(end);
    },
    formatDate(date) {
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();

      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    }
  }
}
</script>
<style lang="scss" scoped>
.machineClass {
  /deep/ .avue-crud__left {
    width: 100%;
  }

  /deep/ .avue-crud__right {
    width: 100%;
  }
}
</style>
